<template>
  <div class="viewFile">
    <div class="iframeBox">
      <iframe
        :src="url"
        style="width: 100%; height: 98%; border: none; padding: 0"
      ></iframe>
      <!-- 测试嵌入三方网页 -->
      <!-- <iframe src="https://gushitong.baidu.com/stock/hk-02390" width="100%" height="100%"></iframe> -->
    </div>

    <a-button class="saveBtn" type="primary" @click="saveFile"> 保存 </a-button>

    <a-button class="dowBtn" type="primary" @click="downloadFile">
      下载
    </a-button>

    <!-- 保存确认 -->
    <SaveModal
      v-if="saveModalShow"
      :visible="saveModalShow"
      type="save"
      :options="saveOptions"
      @close="saveModalShow = false"
      @save="saveOk"
    />
  </div>
</template>

<script>
import SaveModal from "@/views/popup/moveModal.vue";
export default {
  name: "viewFile",
  components: {
    SaveModal,
  },
  data() {
    return {
      id: "",
      url: "",
      shareKey: "",
      userFileName: "",
      extensionName: "",
      shareId: "",
      scopeFlag: "",
      saveModalShow: false,
      saveOptions: {
        checkIdList: [],
      },
    };
  },
  computed: {
    // 租户id 企业id
    companyId() {
      return this.$store.state.user.companyId;
    },
    token() {
      return this.$store.state.user.token;
    },
  },
  created() {
    // this.id = decodeURIComponent(window.location.href.split('userFileId=')[1])
    this.id = decodeURIComponent(
      window.location.href.match(/userFileId=([^&]*)/)[1]
    );
    this.url = decodeURIComponent(window.location.href.split("url=")[1]);
    this.shareKey = decodeURIComponent(
      window.location.href.split("shareKey=")[1]
    );
    this.userFileName = decodeURIComponent(
      window.location.href.split("userFileName=")[1]
    );
    this.extensionName = decodeURIComponent(
      window.location.href.split("extensionName=")[1]
    );
    this.shareId = decodeURIComponent(
      window.location.href.split("shareId=")[1]
    );
    // this.scopeFlag = decodeURIComponent(
    //   window.location.href.split("scopeFlag=")[1]
    // );
    this.scopeFlag = 
      window.location.href.split("scopeFlag=")[1];
  },
  methods: {
    // 下载的方法
    downloadFn() {
      const wrap = document.createElement("div");
      wrap.setAttribute("style", "display: none");
      document.body.appendChild(wrap);
      const frame = document.createElement("iframe"); //创建a对象
      frame.setAttribute(
        "src",
        `/star-doc/file/download?&userFileId=${this.id}&token=${this.token}&Tenant-Id=${this.companyId}`
      );
      frame.setAttribute("class", "download-iframe");
      wrap.appendChild(frame);
      let fileName = `${value.userFileName}.${value.extensionName}`;

      this.$apis.shareRecord({
        fileName,
        shareId: this.shareId,
        type: "download",
      });
      setTimeout(() => {
        document.body.removeChild(wrap);
      }, 60000);
    },

    downloadFile() {
      // if ("下载范围为所有") {
      if (this.scopeFlag === "true" ) {
        this.downloadFn();
      } else {
        if (this.token) {
          this.downloadFn();
        } else {
          this.$message.warning(
            "该文件设置了仅Dana AI 智能体用户可下载，请先登录或注册成为平台用户方可进行后续操作"
          );
          const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${this.shareKey}`;
          // 打印新的 URL 或者进行其他操作
          console.log("新的newUrl", newUrl);
          setTimeout(() => {
            window.location.href = newUrl;
          }, 3000);
        }
      }
    },

    // 保存
    saveFile() {
      if (this.token) {
        this.saveModalShow = true;
        // this.saveOptions.checkIdList = [value.userFileId];
        this.saveOptions.checkIdList = [this.id];
      } else {
        this.$message.warning("保存文件需要登录,请登录后保存");

        const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${this.shareKey}`;
        // 打印新的 URL 或者进行其他操作
        console.log("新的newUrl", newUrl);
        setTimeout(() => {
          window.location.href = newUrl;
        }, 2500);
      }
    },

    saveOk(targetId, sourceIds, teamId) {
      let query = {
        targetId,
        sourceIds,
      };
      let apiName = "userFileCopy";
      if (teamId) {
        query = {
          targetId,
          sourceIds,
          teamId,
        };
        apiName = "companyFileCopy";
      }
      this.$apis[apiName](query).then((res) => {
        if (res.code == 200) {
          this.$message.success("文件保存成功");
          this.saveModalShow = false;
          // const file = this.shareFileList.find(
          //     (file) => file.userFileId === this.selectedRowKeys[0]
          // );
          // let fileName = file.isDir
          //     ? file.userFileName
          //     : `${file.userFileName}.${file.extensionName}`;
          // fileName =
          //     this.selectedRowKeys.length > 1
          //         ? `${fileName}等${this.selectedRowKeys.length}个文件`
          //         : fileName;
          // this.$apis.shareRecord({
          //     fileName,
          //     shareId: this.shareId,
          //     type: "forward",
          // });
        }
      });
    },
  },
};
</script>

<style>
.viewFile {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  /* background-color: #404040; */
  /* overflow-y: hidden; */
}

.iframeBox {
  height: 100%;
  height: 100%;
  padding: 0;
  border: none;
  font-size: 20px;
  font-weight: 700;
}

.dowBtn {
  margin-top: 10px;
  position: fixed;
  bottom: 142px;
  right: 100px;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  background-color: #3271ff !important;
  /* 设置按钮的背景颜色 */
  color: #fff;
  /* 设置按钮的文字颜色 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.saveBtn {
  margin-top: 10px;
  position: fixed;
  bottom: 80px;
  right: 100px;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  background-color: #3271ff !important;
  /* 设置按钮的背景颜色 */
  color: #fff;
  /* 设置按钮的文字颜色 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
